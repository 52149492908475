import React, { useState } from 'react';
import Quiz from './components/Quiz';
import DecisionTable from './components/DecisionTable';
import HeartFailureTable from './components/HeartFailureTable';
import HeartFailureTable1 from './components/HeartFailureTable1';

// Importa los datos de las áreas y temas
import emergenciasHipertensivas from './data/cardiologia/emergenciasHipertensivas';
import arritmiasCases from './data/cardiologia/arritmias';
import insuficienciacardiaCases from './data/cardiologia/insuficienciaCardiaca';
// Importa otros temas
import accidenteCerebrovascularCases from './data/neurologia/accidenteCerebrovascular';

function App() {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Estados para mostrar/ocultar las tablas
  const [showHeartFailureTable, setShowHeartFailureTable] = useState(false);
  const [showHeartFailureTable1, setShowHeartFailureTable1] = useState(false);

  // Define las áreas y sus temas
  const subjects = [
    {
      id: 'cardiologia',
      name: 'Cardiología',
      topics: [
        {
          id: 'emergenciasHipertensivas',
          name: 'Emergencias Hipertensivas',
          questions: emergenciasHipertensivas,
          DecisionTableComponents: [DecisionTable], // Ajustado: array con un componente
        },
        {
          id: 'arritmias',
          name: 'Arritmias',
          questions: arritmiasCases,
        },
        {
          id: 'insuficienciacardiaca',
          name: 'Insuficiencia Cardiaca',
          questions: insuficienciacardiaCases,
          // Antes se usaba para pasar las tablas en Quiz. Ahora las controlaremos aquí.
          // DecisionTableComponents: [HeartFailureTable, HeartFailureTable1],
        },
        // Agrega más temas de Cardiología
      ],
    },
    {
      id: 'neurologia',
      name: 'Neurología',
      topics: [
        {
          id: 'accidenteCerebrovascular',
          name: 'Accidente Cerebrovascular',
          questions: accidenteCerebrovascularCases,
        },
        // Agrega más temas de Neurología
      ],
    },
    // Agrega más áreas médicas con sus temas
  ];

  if (!selectedSubject) {
    // Mostrar selección de áreas
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Selecciona un Área Médica
          </h1>
          <div className="flex flex-col space-y-4">
            {subjects.map((subject) => (
              <button
                key={subject.id}
                onClick={() => setSelectedSubject(subject)}
                className="w-full p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                {subject.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!selectedTopic) {
    // Mostrar selección de temas dentro del área seleccionada
    return (
      <div className="min-h-screen bg-gray-100 py-8">
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          {/* Botón para volver al menú de áreas */}
          <button
            onClick={() => setSelectedSubject(null)}
            className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
          >
            Volver al Menú de Áreas
          </button>

          <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Selecciona un Tema de {selectedSubject.name}
          </h1>
          <div className="flex flex-col space-y-4">
            {selectedSubject.topics.map((topic) => (
              <button
                key={topic.id}
                onClick={() => setSelectedTopic(topic)}
                className="w-full p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                {topic.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const handleBackToTopics = () => {
    setShowHeartFailureTable(false);
    setShowHeartFailureTable1(false);
    setSelectedTopic(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        {/* Botón para volver al menú de temas */}
        <button
          onClick={handleBackToTopics}
          className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Volver al Menú de Temas
        </button>

        <Quiz
          title={selectedTopic.name}
          questions={selectedTopic.questions}
          onBack={() => setSelectedTopic(null)}
        />

        {/* Si el tema es insuficiencia cardiaca, mostrar los botones para las tablas */}
        {selectedTopic.id === 'insuficienciacardiaca' && (
          <div className="mt-6 space-x-4">
            <button
              onClick={() => setShowHeartFailureTable((prev) => !prev)}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              {showHeartFailureTable ? 'Ocultar HeartFailureTable' : 'Mostrar HeartFailureTable'}
            </button>

            <button
              onClick={() => setShowHeartFailureTable1((prev) => !prev)}
              className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600"
            >
              {showHeartFailureTable1 ? 'Ocultar HeartFailureTable1' : 'Mostrar HeartFailureTable1'}
            </button>
          </div>
        )}

        {showHeartFailureTable && (
          <div className="mt-6">
            <HeartFailureTable />
          </div>
        )}

        {showHeartFailureTable1 && (
          <div className="mt-6">
            <HeartFailureTable1 />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;