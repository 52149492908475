import React, { useState, useEffect } from 'react'; 
import { BookOpen, Check, X } from 'lucide-react';

function Quiz({ title, questions, onBack, DecisionTableComponents }) {
  // Estados
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [studyHistory, setStudyHistory] = useState(() => {
    try {
      const saved = localStorage.getItem(`studyHistory_${title}`);
      return saved ? JSON.parse(saved) : {
        attempts: 0,
        correct: 0,
        questionHistory: {}
      };
    } catch {
      return {
        attempts: 0,
        correct: 0,
        questionHistory: {}
      };
    }
  });
  const [reviewMode, setReviewMode] = useState(false);
  const [showDecisionTable, setShowDecisionTable] = useState(false);
  const [selectedYear, setSelectedYear] = useState('Todos');

  // Obtener los años disponibles
  const years = [...new Set(questions.map(question => question.year))];

  // Filtrar preguntas por año
  const filteredQuestions = selectedYear === 'Todos'
    ? questions
    : questions.filter(question => question.year === selectedYear);

  // Asegurar que currentQuestion esté dentro del rango
  useEffect(() => {
    if (currentQuestion >= filteredQuestions.length) {
      setCurrentQuestion(0);
    }
  }, [filteredQuestions, currentQuestion]);

  // Reiniciar estados al cambiar de pregunta
  const currentQuestionData = filteredQuestions[currentQuestion];

  useEffect(() => {
    setSelectedAnswer(null);
    setShowExplanation(false);
    setShowDecisionTable(false);
  }, [currentQuestionData]);

  // Guardar en localStorage
  useEffect(() => {
    try {
      localStorage.setItem(`studyHistory_${title}`, JSON.stringify(studyHistory));
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }, [studyHistory, title]);

  // Validar que tenemos preguntas
  if (!filteredQuestions || filteredQuestions.length === 0 || !currentQuestionData) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <p className="text-red-600">No hay preguntas disponibles para el año seleccionado.</p>
          <button
            onClick={() => setSelectedYear('Todos')}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Ver Todas las Preguntas
          </button>
        </div>
      </div>
    );
  }

  const handleAnswer = (optionId) => {
    if (!currentQuestionData) return;

    const isCorrect = currentQuestionData.options.find(opt => opt.id === optionId)?.correct;
    
    setSelectedAnswer(optionId);
    setShowExplanation(true);

    setStudyHistory(prev => {
      const questionId = currentQuestionData.id.toString();
      const questionHistory = prev.questionHistory[questionId] || { attempts: 0, correct: 0 };
      
      return {
        ...prev,
        attempts: prev.attempts + 1,
        correct: prev.correct + (isCorrect ? 1 : 0),
        questionHistory: {
          ...prev.questionHistory,
          [questionId]: {
            attempts: questionHistory.attempts + 1,
            correct: questionHistory.correct + (isCorrect ? 1 : 0),
            lastAttempt: new Date().toISOString()
          }
        }
      };
    });
  };

  const nextQuestion = () => {
    setCurrentQuestion((prev) => (prev + 1) % filteredQuestions.length);
  };

  const resetStudy = () => {
    setStudyHistory({
      attempts: 0,
      correct: 0,
      questionHistory: {}
    });
    setCurrentQuestion(0);
    setReviewMode(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Botón para volver al menú de temas */}
        <button
          onClick={onBack}
          className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Volver al Menú de Temas
        </button>

        {/* Header */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-center text-gray-800 mb-2">
            {title}
          </h1>
          <div className="flex justify-between items-center flex-wrap gap-2">
            <div className="text-sm">
              <span className="font-medium">Intentos totales: </span>
              {studyHistory.attempts}
            </div>
            <div className="text-sm text-green-600">
              <span className="font-medium">Correctas: </span>
              {studyHistory.correct} ({studyHistory.attempts > 0 
                ? Math.round((studyHistory.correct/studyHistory.attempts) * 100) 
                : 0}%)
            </div>
            <button
              onClick={resetStudy}
              className="px-4 py-2 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Reiniciar
            </button>
            <button
              onClick={() => setReviewMode(!reviewMode)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {reviewMode ? 'Modo Práctica' : 'Ver Estadísticas'}
            </button>
          </div>
        </div>

        {/* Filtro por Año */}
        <div className="mb-6">
          <label htmlFor="year-select" className="block text-gray-700 font-medium mb-2">
            Filtrar por Año:
          </label>
          <select
            id="year-select"
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
              setCurrentQuestion(0); // Reiniciar a la primera pregunta del filtro
            }}
            className="w-full p-2 border border-gray-300 rounded-lg"
          >
            <option value="Todos">Todos</option>
            {years.sort().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {!reviewMode ? (
          <>
            {/* Navegación de Preguntas */}
            <QuestionNavigation
              questions={filteredQuestions}
              currentQuestion={currentQuestion}
              setCurrentQuestion={(index) => {
                setCurrentQuestion(index);
              }}
            />

            {/* Pregunta */}
            <div className="mb-6">
              <div className="flex items-start gap-2 mb-4">
                <BookOpen className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                  <div className="text-sm text-gray-500 mb-2">RM {currentQuestionData.year}</div>
                  <p className="text-gray-800">{currentQuestionData.case}</p>
                  <p className="font-medium mt-2">{currentQuestionData.question}</p>
                </div>
              </div>

              {/* Opciones */}
              <div className="space-y-3">
                {currentQuestionData.options.map((option) => (
                  <button
                    key={option.id}
                    onClick={() => !showExplanation && handleAnswer(option.id)}
                    className={`w-full p-4 text-left rounded-lg transition-all ${
                      !selectedAnswer 
                        ? 'hover:bg-gray-50 border border-gray-200'
                        : option.correct
                          ? 'bg-green-50 border-green-500'
                          : option.id === selectedAnswer
                            ? 'bg-red-50 border-red-500'
                            : 'border border-gray-200'
                    }`}
                    disabled={showExplanation}
                  >
                    <div className="flex items-center gap-2">
                      {showExplanation && option.correct && (
                        <Check className="w-5 h-5 text-green-500" />
                      )}
                      {showExplanation && option.id === selectedAnswer && !option.correct && (
                        <X className="w-5 h-5 text-red-500" />
                      )}
                      <span className="font-medium">{option.id})</span>
                      <span>{option.text}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Explicación */}
            {showExplanation && (
              <div className="mt-6 p-4 bg-blue-50 rounded-lg">
                <h3 className="font-bold text-blue-800 mb-2">Explicación:</h3>
                <p className="text-blue-900 mb-4">{currentQuestionData.explanation}</p>
                
                <h4 className="font-bold text-blue-800 mb-2">Puntos Clave:</h4>
                <ul className="list-disc list-inside text-blue-900">
                  {currentQuestionData.keyPoints.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>

                {/* Mostrar botón si hay DecisionTableComponents */}
                {DecisionTableComponents && DecisionTableComponents.length > 0 && (
                  <>
                    <button
                      onClick={() => setShowDecisionTable(!showDecisionTable)}
                      className="w-full mt-4 p-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
                    >
                      {showDecisionTable ? 'Ocultar Tabla(s) de Decisión Rápida' : 'Ver Tabla(s) de Decisión Rápida'}
                    </button>

                    {/* Mostrar las tablas si showDecisionTable es true */}
                    {showDecisionTable && DecisionTableComponents.map((Component, index) => (
                      <div key={index} className="mt-4">
                        <Component />
                      </div>
                    ))}
                  </>
                )}

                <button
                  onClick={nextQuestion}
                  className="w-full mt-4 p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Siguiente Pregunta
                </button>
              </div>
            )}
          </>
        ) : (
          // Modo revisión
          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Estadísticas por Pregunta</h2>
            {questions.map(question => {
              const stats = studyHistory.questionHistory[question.id.toString()] || { attempts: 0, correct: 0 };
              const effectiveness = stats.attempts > 0 
                ? Math.round((stats.correct / stats.attempts) * 100) 
                : 0;

              return (
                <div key={question.id} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <div className="font-medium">Pregunta {question.id} (RM {question.year})</div>
                    <div className={`text-sm ${
                      effectiveness >= 70 ? 'text-green-600' : 
                      effectiveness >= 40 ? 'text-yellow-600' : 
                      'text-red-600'
                    }`}>
                      {effectiveness}% de efectividad
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mb-2">
                    Intentos: {stats.attempts} | Correctas: {stats.correct}
                  </p>
                  {stats.lastAttempt && (
                    <p className="text-xs text-gray-500">
                      Último intento: {new Date(stats.lastAttempt).toLocaleDateString()}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

// Componente de Navegación de Preguntas
function QuestionNavigation({ questions, currentQuestion, setCurrentQuestion }) {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-bold text-gray-800 mb-2">Navegación de Preguntas</h2>
      <div className="flex flex-wrap gap-2">
        {questions.map((question, index) => (
          <button
            key={question.id}
            onClick={() => setCurrentQuestion(index)}
            className={`px-3 py-2 rounded-lg border ${
              index === currentQuestion
                ? 'bg-blue-500 text-white border-blue-500'
                : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-100'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Quiz;