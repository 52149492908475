import React, { useState } from 'react';
import './FlashcardCell.css'; // Asegúrate de contar con el CSS

function HeartFailureTable1() {
  const [expandedRows, setExpandedRows] = useState([]);
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [revealedText, setRevealedText] = useState(''); // Solo un texto a la vez

  const toggleRow = (index) => {
    setExpandedRows((prev) =>
      prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index]
    );
  };

  const tableData = [
    {
      code: 'Disnea',
      weight: 10,
      justification:
        'La disnea es el síntoma más frecuente y relevante en la insuficiencia cardíaca izquierda debido al aumento de la presión capilar pulmonar que causa congestión pulmonar. Este síntoma suele ser progresivo y empeora con el esfuerzo, llegando incluso a presentarse en reposo en estadios avanzados, lo que dificulta las actividades diarias del paciente.',
      phrases: ['disnea', 'respiración rápida', 'taquipnea'],
    },
    {
      code: 'Ortopnea',
      weight: 9,
      justification:
        'La dificultad para respirar al estar acostado se debe al aumento del retorno venoso y redistribución de líquidos hacia los pulmones, agravando la congestión pulmonar. Esto genera incomodidad al paciente, quien tiende a dormir con múltiples almohadas o en posición semi-sentada.',
      phrases: ['ortopnea'],
    },
    {
      code: 'Disnea Paroxística Nocturna',
      weight: 8,
      justification:
        'Episodios súbitos de disnea durante la noche por la reabsorción de edemas periféricos y aumento de la presión pulmonar. El paciente se despierta con sensación de ahogo y debe sentarse para aliviar el síntoma.',
      phrases: ['disnea paroxística nocturna'],
    },
    {
      code: 'Congestión Pulmonar (Estertores)',
      weight: 8,
      justification:
        'La acumulación de líquido en los alvéolos causa estertores crepitantes en la auscultación, indicando congestión pulmonar significativa. A menudo estos se escuchan en bases pulmonares y pueden ascender conforme se agrava la IC.',
      phrases: ['congestión pulmonar', 'roncantes y subcrepitantes bilaterales'],
    },
    {
      code: 'Tercer Ruido Cardíaco (S3)',
      weight: 7,
      justification:
        'El S3 indica sobrecarga de volumen y disfunción sistólica ventricular izquierda, siendo específico en mayores de 40 años. Su presencia sugiere un ventrículo dilatado y con pobre función contráctil.',
      phrases: ['tercer ruido'],
    },
    {
      code: 'Fatigabilidad',
      weight: 6,
      justification:
        'La disminución del gasto cardíaco lleva a menor perfusión tisular, causando fatiga y debilidad. El paciente se cansa con actividades cotidianas que antes realizaba sin esfuerzo.',
      phrases: ['fatigabilidad'],
    },
    {
      code: 'Hemoptisis',
      weight: 5,
      justification:
        'La ruptura de capilares pulmonares por hipertensión pulmonar puede causar expectoración sanguinolenta, reflejando congestión severa en el lecho pulmonar.',
      phrases: ['hemoptisis'],
    },
    {
      code: 'Taquipnea',
      weight: 5,
      justification:
        'El aumento de la frecuencia respiratoria es una respuesta compensatoria a la hipoxia causada por la congestión pulmonar. El paciente respira más rápido intentando mejorar la oxigenación.',
      phrases: ['FR 70 X', 'taquipnea'],
    },
    {
      code: 'Soplo Sistólico en Mesocardio',
      weight: 4,
      justification:
        'Puede indicar regurgitación mitral secundaria a dilatación ventricular izquierda, contribuyendo a la insuficiencia cardíaca. El ventrículo dilatado altera el aparato valvular, generando insuficiencia mitral funcional.',
      phrases: ['soplo sistólico en mesocardio'],
    },
  ];

  const columnDisplayNames = {
    code: 'Código (Hallazgo Clínico)',
    weight: 'Peso (de 10)',
    justification: 'Justificación',
    phrases: 'Palabras/Frases en Enunciados Representando el Código',
  };

  const columns = Object.keys(columnDisplayNames);

  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((item, idx) => {
        const cellKey = `${idx}-${columnKey}`;
        if (newHiddenColumns[columnKey]) {
          newHiddenCells[cellKey] = true;
        } else {
          delete newHiddenCells[cellKey];
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;

    if (areAllCellsVisible) {
      const newHiddenCells = {};
      tableData.forEach((item, idx) => {
        columns.forEach((colKey) => {
          if (colKey !== 'code') {
            const cellKey = `${idx}-${colKey}`;
            newHiddenCells[cellKey] = true;
          }
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      setHiddenCells({});
    }
  };

  // Función para manejar el "Mostrar/Ocultar" del texto en la sección inferior
  const handleRevealText = (text, rowIdx, fieldKey) => {
    // Si el texto revelado es el mismo que se intenta mostrar, lo ocultamos
    if (revealedText === text) {
      setRevealedText('');
    } else {
      setRevealedText(text);
    }

    // Además, aseguramos que la celda vuelva a la vista frontal (sin estar volteada)
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevHiddenCells) => {
      const newState = { ...prevHiddenCells };
      // Aseguramos que esté en estado "no volteado"
      if (newState[cellKey]) {
        delete newState[cellKey];
      }
      return newState;
    });
  };

  return (
    <div className="overflow-x-auto mt-4">
      <h4 className="font-bold text-blue-800 mb-4 text-center text-xl">
        Tabla de Códigos de Mayor Peso para Insuficiencia Cardíaca Izquierda
      </h4>

      <div className="mb-4 text-center">
        <button
          onClick={toggleAllCells}
          className="mb-2 px-3 py-1 border rounded bg-green-500 text-white hover:bg-green-600 transition"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
      </div>

      <div className="mb-6 text-center">
        <span className="font-bold mr-2">Mostrar/Ocultar Columnas:</span>
        {columns.map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="mr-2 mb-2 px-3 py-1 border rounded bg-blue-500 text-white hover:bg-blue-600 transition"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
      </div>

      <table
        className="w-full text-left border-collapse border-spacing-2"
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th className="border px-4 py-2 bg-gray-200 text-center">Acción</th>
            {columns.map((columnKey) => (
              <th
                key={columnKey}
                className="border px-4 py-2 bg-gray-200 text-center"
                style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
              >
                {columnDisplayNames[columnKey]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <tr className="transition duration-200">
                <td className="border px-4 py-4 align-top text-center">
                  <button
                    onClick={() => toggleRow(idx)}
                    className="text-blue-500 underline"
                  >
                    {expandedRows.includes(idx) ? 'Ocultar' : 'Mostrar'}
                  </button>
                </td>

                {/* CODE */}
                <td
                  className="border px-4 py-4 font-bold align-top"
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-code`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'code')}
                      >
                        {item.code}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          className="text-blue-500 underline"
                          onClick={() => handleRevealText(item.code, idx, 'code')}
                        >
                          {revealedText === item.code ? 'Ocultar' : 'Mostrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>

                {/* WEIGHT */}
                <td
                  className="border px-4 py-4 align-top text-center"
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-weight`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'weight')}
                      >
                        {item.weight}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          className="text-blue-500 underline"
                          onClick={() =>
                            handleRevealText(`Peso: ${item.weight}`, idx, 'weight')
                          }
                        >
                          {revealedText === `Peso: ${item.weight}`
                            ? 'Ocultar'
                            : 'Mostrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>

                {/* JUSTIFICATION */}
                <td
                  className="border px-4 py-4 align-top"
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-justification`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'justification')}
                      >
                        {item.justification}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          className="text-blue-500 underline"
                          onClick={() =>
                            handleRevealText(item.justification, idx, 'justification')
                          }
                        >
                          {revealedText === item.justification
                            ? 'Ocultar'
                            : 'Mostrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>

                {/* PHRASES */}
                <td
                  className="border px-4 py-4 align-top"
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-phrases`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'phrases')}
                      >
                        {item.phrases.join(', ')}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          className="text-blue-500 underline"
                          onClick={() =>
                            handleRevealText(`Frases: ${item.phrases.join(', ')}`, idx, 'phrases')
                          }
                        >
                          {revealedText === `Frases: ${item.phrases.join(', ')}` 
                            ? 'Ocultar' 
                            : 'Mostrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              {expandedRows.includes(idx) && (
                <tr className="transition duration-200">
                  <td className="border px-4 py-4"></td>
                  <td
                    className="border px-4 py-4"
                    colSpan={columns.length}
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    <strong>Detalles Adicionales:</strong>
                    <ul className="list-disc list-inside">
                      {item.phrases.map((phrase, index) => (
                        <li key={index}>{phrase}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {revealedText && (
        <div className="mt-6 p-4 border border-gray-300 bg-gray-100">
          <h5 className="font-bold mb-2">Texto Mostrado:</h5>
          <p>{revealedText}</p>
        </div>
      )}
    </div>
  );
}

export default HeartFailureTable1;