import React, { useState } from 'react';
import './FlashcardCell.css'; // Asegúrate de tener este archivo CSS

function DecisionTable() {
  const [expandedSections, setExpandedSections] = useState({});
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});

  const toggleSection = (organ) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [organ]: !prevState[organ],
    }));
  };

  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((item, idx) => {
        if (columnKey === 'codes') {
          // Ocultar la celda principal de códigos
          const codeCellKeyMain = `${idx}-codes-0`;
          if (newHiddenColumns[columnKey]) {
            newHiddenCells[codeCellKeyMain] = true;
          } else {
            delete newHiddenCells[codeCellKeyMain];
          }

          // Ocultar códigos adicionales
          item.codes.slice(1).forEach((code, codeIdx) => {
            const codeCellKey = `${idx}-codes-${codeIdx + 1}`;
            if (newHiddenColumns[columnKey]) {
              newHiddenCells[codeCellKey] = true;
            } else {
              delete newHiddenCells[codeCellKey];
            }
          });
        } else {
          const cellKey = `${idx}-${columnKey}`;

          if (newHiddenColumns[columnKey]) {
            // Ocultamos las celdas de esta columna
            newHiddenCells[cellKey] = true;
          } else {
            // Mostramos las celdas de esta columna
            delete newHiddenCells[cellKey];
          }
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;

    if (areAllCellsVisible) {
      // Ocultar todas las celdas excepto la columna 'organ'
      const newHiddenCells = {};
      tableData.forEach((item, idx) => {
        // Ocultar la celda principal de códigos
        newHiddenCells[`${idx}-codes-0`] = true;

        ['drug', 'goal'].forEach((fieldKey) => {
          const cellKey = `${idx}-${fieldKey}`;
          newHiddenCells[cellKey] = true;
        });

        // Ocultar códigos adicionales
        item.codes.slice(1).forEach((code, codeIdx) => {
          const codeCellKey = `${idx}-codes-${codeIdx + 1}`;
          newHiddenCells[codeCellKey] = true;
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      // Mostrar todas las celdas
      setHiddenCells({});
    }
  };

  const columnDisplayNames = {
    organ: 'Órgano',
    codes: 'Códigos',
    drug: 'Droga',
    goal: 'Meta PA',
  };

  const tableData = [
    {
      organ: 'Edema Pulmonar',
      codes: [
        '• Infiltrados "alas de mariposa" (10)',
        '• SatO2 < 95% (9)',
        '• Disnea súbita (9)',
        '• Crepitantes bilaterales (8)',
        '• Hemoptisis (8)',
      ],
      drug: 'Nitroprusiato',
      goal: '↓25% en 1-2h',
    },
    {
      organ: 'ACV Isquémico',
      codes: [
        '• TC: hipodensidad aguda (10)',
        '• Déficit focal agudo (9)',
        '• Hemiparesia (9)',
        '• Disartria (8)',
      ],
      drug: 'Labetalol',
      goal: '<180/105',
    },
    {
      organ: 'ACV Hemorrágico',
      codes: [
        '• TC: hiperdensidad (10)',
        '• Cefalea súbita severa (9)',
        '• Deterioro conciencia (9)',
        '• Vómitos proyectiles (8)',
      ],
      drug: 'Labetalol',
      goal: '<140 en 1h',
    },
    {
      organ: 'SCA/IAM',
      codes: [
        '• EKG: ST elevado (10)',
        '• Dolor precordial típico (9)',
        '• Troponina + (9)',
        '• Diaforesis (8)',
      ],
      drug: 'Nitroglicerina',
      goal: '↓20-30%',
    },
    {
      organ: 'Disección Aórtica',
      codes: [
        '• TAC: flap intimal (10)',
        '• Dolor desgarrante (9)',
        '• PA diferencial (9)',
        '• Soplo AR nuevo (8)',
      ],
      drug: 'Esmolol + Nitroprusiato',
      goal: 'PAS 100-120',
    },
    {
      organ: 'Preeclampsia Severa',
      codes: [
        '• Embarazo >20s + PA ≥160/110 (10)',
        '• Proteinuria +++ (9)',
        '• Síntomas cerebrales (9)',
        '• ROT ++++ (8)',
      ],
      drug: 'Labetalol',
      goal: '≤150/100',
    },
  ];

  return (
    <div className="overflow-x-auto mt-4">
      <h4 className="font-bold text-blue-800 mb-4 text-center text-xl">
        EMERGENCIAS HIPERTENSIVAS: TABLA DE DECISIÓN RÁPIDA
      </h4>

      {/* Botón para mostrar/ocultar toda la información */}
      <div className="mb-4 text-center">
        <button
          onClick={toggleAllCells}
          className="mb-2 px-3 py-1 border rounded bg-green-500 text-white hover:bg-green-600 transition"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
      </div>

      {/* Botones para mostrar/ocultar columnas */}
      <div className="mb-6 text-center">
        <span className="font-bold mr-2">
          Mostrar/Ocultar Información de Columnas:
        </span>
        {Object.keys(columnDisplayNames).map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="mr-2 mb-2 px-3 py-1 border rounded bg-blue-500 text-white hover:bg-blue-600 transition"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
      </div>

      <table className="w-full text-left border-collapse border-spacing-2">
        <thead>
          <tr>
            {Object.keys(columnDisplayNames).map((columnKey) => (
              <th
                key={columnKey}
                className="border px-4 py-2 bg-gray-200 text-center"
              >
                {columnDisplayNames[columnKey]}
              </th>
            ))}
            <th className="border px-4 py-2 bg-gray-200 text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <tr className={`transition duration-200`}>
                {/* Columna Órgano */}
                <td className="border px-4 py-4 font-bold align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-organ`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'organ')}
                      >
                        {item.organ}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'organ')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Códigos */}
                <td className="border px-4 py-4 align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-codes-0`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'codes-0')}
                      >
                        {item.codes[0]}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'codes-0')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Droga */}
                <td className="border px-4 py-4 align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-drug`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'drug')}
                      >
                        {item.drug}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'drug')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Meta PA */}
                <td className="border px-4 py-4 align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-goal`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'goal')}
                      >
                        {item.goal}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'goal')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Acción */}
                <td className="border px-4 py-4 align-top">
                  <button
                    onClick={() => toggleSection(item.organ)}
                    className="text-blue-500 underline"
                  >
                    {expandedSections[item.organ] ? 'Ocultar' : 'Mostrar'}
                  </button>
                </td>
              </tr>
              {expandedSections[item.organ] &&
                item.codes.slice(1).map((code, codeIdx) => (
                  <tr
                    key={`${idx}-${codeIdx}`}
                    className={`transition duration-200`}
                  >
                    {/* Celda vacía para Órgano */}
                    <td className="border px-4 py-4"></td>
                    {/* Códigos adicionales */}
                    <td className="border px-4 py-4 align-top">
                      <div
                        className={`flashcard-cell ${
                          hiddenCells[`${idx}-codes-${codeIdx + 1}`]
                            ? 'flipped'
                            : ''
                        }`}
                      >
                        <div className="flashcard-cell-inner">
                          <div
                            className="flashcard-cell-front"
                            onClick={() =>
                              toggleCell(idx, `codes-${codeIdx + 1}`)
                            }
                          >
                            {item.codes[codeIdx + 1]}
                          </div>
                          <div
                            className="flashcard-cell-back"
                            onClick={() =>
                              toggleCell(idx, `codes-${codeIdx + 1}`)
                            }
                          >
                            <button className="text-blue-500 underline">
                              Mostrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* Celdas vacías para las demás columnas */}
                    <td className="border px-4 py-4"></td>
                    <td className="border px-4 py-4"></td>
                    <td className="border px-4 py-4"></td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <h4 className="font-bold text-blue-800 mt-8 mb-4 text-center text-xl">
        Elección de Droga:
      </h4>
      <ul className="list-disc list-inside text-blue-900 text-lg">
        <li>Pulmón → Nitroprusiato</li>
        <li>Cerebro → Labetalol</li>
        <li>Corazón → Nitroglicerina</li>
        <li>Aorta → Esmolol + Nitroprusiato</li>
      </ul>
    </div>
  );
}

export default DecisionTable;