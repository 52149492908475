import React, { useState } from 'react';
import './FlashcardCell.css'; // Asegúrate de tener este archivo CSS

function HeartFailureTable() {
  const [expandedSections, setExpandedSections] = useState({});
  const [hiddenCells, setHiddenCells] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState({});

  const toggleSection = (category) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const toggleColumn = (columnKey) => {
    setHiddenColumns((prevState) => {
      const newHiddenColumns = {
        ...prevState,
        [columnKey]: !prevState[columnKey],
      };

      const newHiddenCells = { ...hiddenCells };
      tableData.forEach((item, idx) => {
        if (columnKey === 'codes') {
          // Ocultar la celda principal de códigos
          const codeCellKeyMain = `${idx}-codes-0`;
          if (newHiddenColumns[columnKey]) {
            newHiddenCells[codeCellKeyMain] = true;
          } else {
            delete newHiddenCells[codeCellKeyMain];
          }

          // Ocultar códigos adicionales
          item.codes.slice(1).forEach((code, codeIdx) => {
            const codeCellKey = `${idx}-codes-${codeIdx + 1}`;
            if (newHiddenColumns[columnKey]) {
              newHiddenCells[codeCellKey] = true;
            } else {
              delete newHiddenCells[codeCellKey];
            }
          });
        } else {
          const cellKey = `${idx}-${columnKey}`;

          if (newHiddenColumns[columnKey]) {
            // Ocultamos las celdas de esta columna
            newHiddenCells[cellKey] = true;
          } else {
            // Mostramos las celdas de esta columna
            delete newHiddenCells[cellKey];
          }
        }
      });

      setHiddenCells(newHiddenCells);
      return newHiddenColumns;
    });
  };

  const toggleCell = (rowIdx, fieldKey) => {
    const cellKey = `${rowIdx}-${fieldKey}`;
    setHiddenCells((prevState) => ({
      ...prevState,
      [cellKey]: !prevState[cellKey],
    }));
  };

  const toggleAllCells = () => {
    const areAllCellsVisible = Object.keys(hiddenCells).length === 0;

    if (areAllCellsVisible) {
      // Ocultar todas las celdas excepto la columna 'category'
      const newHiddenCells = {};
      tableData.forEach((item, idx) => {
        // Ocultar la celda principal de códigos
        newHiddenCells[`${idx}-codes-0`] = true;

        // Ocultar códigos adicionales
        item.codes.slice(1).forEach((code, codeIdx) => {
          const codeCellKey = `${idx}-codes-${codeIdx + 1}`;
          newHiddenCells[codeCellKey] = true;
        });
      });
      setHiddenCells(newHiddenCells);
    } else {
      // Mostrar todas las celdas
      setHiddenCells({});
    }
  };

  const columnDisplayNames = {
    category: 'Categoría',
    codes: 'Síntomas',
  };

  const tableData = [
    {
      category: 'Alimentación',
      codes: [
        '• Lactancia entrecortada',
        '• Succión débil',
        '• Sudoración profusa durante la alimentación',
      ],
    },
    {
      category: 'Respiratorios',
      codes: ['• Taquipnea', '• Disnea'],
    },
    {
      category: 'Cardíacos',
      codes: ['• Soplo sistólico', '• Taquicardia'],
    },
    {
      category: 'Sistémicos',
      codes: ['• Hepatomegalia', '• Pálidez', '• Adelgazamiento'],
    },
  ];

  return (
    <div className="overflow-x-auto mt-4">
      <h4 className="font-bold text-blue-800 mb-4 text-center text-xl">
        INSUFICIENCIA CARDÍACA PEDIÁTRICA
      </h4>

      {/* Botón para mostrar/ocultar toda la información */}
      <div className="mb-4 text-center">
        <button
          onClick={toggleAllCells}
          className="mb-2 px-3 py-1 border rounded bg-green-500 text-white hover:bg-green-600 transition"
        >
          {Object.keys(hiddenCells).length === 0
            ? 'Ocultar toda la información'
            : 'Mostrar toda la información'}
        </button>
      </div>

      {/* Botones para mostrar/ocultar columnas */}
      <div className="mb-6 text-center">
        <span className="font-bold mr-2">
          Mostrar/Ocultar Información de Columnas:
        </span>
        {Object.keys(columnDisplayNames).map((columnKey) => (
          <button
            key={columnKey}
            onClick={() => toggleColumn(columnKey)}
            className="mr-2 mb-2 px-3 py-1 border rounded bg-blue-500 text-white hover:bg-blue-600 transition"
          >
            {hiddenColumns[columnKey]
              ? `Mostrar ${columnDisplayNames[columnKey]}`
              : `Ocultar ${columnDisplayNames[columnKey]}`}
          </button>
        ))}
      </div>

      <table className="w-full text-left border-collapse border-spacing-2">
        <thead>
          <tr>
            {Object.keys(columnDisplayNames).map((columnKey) => (
              <th
                key={columnKey}
                className="border px-4 py-2 bg-gray-200 text-center"
              >
                {columnDisplayNames[columnKey]}
              </th>
            ))}
            <th className="border px-4 py-2 bg-gray-200 text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <tr className={`transition duration-200`}>
                {/* Columna Categoría */}
                <td className="border px-4 py-4 font-bold align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-category`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'category')}
                      >
                        {item.category}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'category')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Síntomas */}
                <td className="border px-4 py-4 align-top">
                  <div
                    className={`flashcard-cell ${
                      hiddenCells[`${idx}-codes-0`] ? 'flipped' : ''
                    }`}
                  >
                    <div className="flashcard-cell-inner">
                      <div
                        className="flashcard-cell-front"
                        onClick={() => toggleCell(idx, 'codes-0')}
                      >
                        {item.codes[0]}
                      </div>
                      <div
                        className="flashcard-cell-back"
                        onClick={() => toggleCell(idx, 'codes-0')}
                      >
                        <button className="text-blue-500 underline">
                          Mostrar
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                {/* Columna Acción */}
                <td className="border px-4 py-4 align-top">
                  <button
                    onClick={() => toggleSection(item.category)}
                    className="text-blue-500 underline"
                  >
                    {expandedSections[item.category] ? 'Ocultar' : 'Mostrar'}
                  </button>
                </td>
              </tr>
              {expandedSections[item.category] &&
                item.codes.slice(1).map((code, codeIdx) => (
                  <tr
                    key={`${idx}-${codeIdx}`}
                    className={`transition duration-200`}
                  >
                    {/* Celda vacía para Categoría */}
                    <td className="border px-4 py-4"></td>
                    {/* Síntomas adicionales */}
                    <td className="border px-4 py-4 align-top">
                      <div
                        className={`flashcard-cell ${
                          hiddenCells[`${idx}-codes-${codeIdx + 1}`]
                            ? 'flipped'
                            : ''
                        }`}
                      >
                        <div className="flashcard-cell-inner">
                          <div
                            className="flashcard-cell-front"
                            onClick={() =>
                              toggleCell(idx, `codes-${codeIdx + 1}`)
                            }
                          >
                            {item.codes[codeIdx + 1]}
                          </div>
                          <div
                            className="flashcard-cell-back"
                            onClick={() =>
                              toggleCell(idx, `codes-${codeIdx + 1}`)
                            }
                          >
                            <button className="text-blue-500 underline">
                              Mostrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* Celda vacía para Acción */}
                    <td className="border px-4 py-4"></td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HeartFailureTable;