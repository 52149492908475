const arritmiasCases = [
    {
      id: 1,
      year: '2024 A',
      case: 'Varón de 65 años con antecedente de cardiopatía isquémica presenta palpitaciones y mareos. Examen: PA 90/60 mmHg, FC 150 X\'. EKG: taquicardia supraventricular.',
      question: '¿Cuál es el tratamiento de elección?',
      options: [
        { id: 'A', text: 'Adenosina', correct: true },
        { id: 'B', text: 'Amiodarona' },
        { id: 'C', text: 'Betabloqueantes' },
        { id: 'D', text: 'Digoxina' }
      ],
      explanation: 'La adenosina es el tratamiento de elección para la taquicardia supraventricular paroxística debido a su efecto sobre el nodo AV.',
      keyPoints: [
        'Taquicardia supraventricular',
        'Paciente hemodinámicamente estable',
        'Adenosina actúa en el nodo AV'
      ],
      organSystem: 'corazon'
    },
    {
      id: 2,
      year: '2023 B',
      case: 'Mujer de 70 años con antecedentes de hipertensión y diabetes presenta palpitaciones y disnea súbita. Examen: PA 100/60 mmHg, FC 160 X\'. EKG: fibrilación auricular con respuesta ventricular rápida.',
      question: '¿Cuál es el manejo inicial más adecuado?',
      options: [
        { id: 'A', text: 'Administrar digoxina' },
        { id: 'B', text: 'Cardioversión eléctrica sincronizada', correct: true },
        { id: 'C', text: 'Administrar warfarina' },
        { id: 'D', text: 'Observar y reevaluar en 24 horas' }
      ],
      explanation: 'En pacientes inestables hemodinámicamente con fibrilación auricular, la cardioversión eléctrica sincronizada es el manejo de elección.',
      keyPoints: [
        'Fibrilación auricular',
        'Inestabilidad hemodinámica',
        'Cardioversión eléctrica inmediata'
      ],
      organSystem: 'corazon'
    },
    // Agrega más casos clínicos aquí...
  ];
  
  export default arritmiasCases;