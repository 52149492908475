const emergenciasHipertensivas = [
    {
      id: 1,
      year: '2024 B',
      case: 'Varón de 45 años acude por edema de miembros inferiores, disnea, dolor torácico y hemoptisis. Examen: PA 190/125 mmHg, FC 102 X, FR 22 X, SatO2: 90%. EKG: hipertrofia ventricular izquierda. Rx tórax: infiltrado alveolar bilateral.',
      question: '¿Cuál es el tratamiento indicado?',
      options: [
        { id: 'A', text: 'Nifedipino' },
        { id: 'B', text: 'Nitroprusiato de sodio', correct: true },
        { id: 'C', text: 'Labetalol' },
        { id: 'D', text: 'Nitroglicerina' }
      ],
      explanation: 'El Nitroprusiato es de elección en edema pulmonar hipertensivo por su rápido inicio de acción y potente efecto vasodilatador arterial y venoso.',
      keyPoints: [
        'Edema pulmonar hipertensivo',
        'Infiltrado alveolar bilateral',
        'Hemoptisis',
        'Necesidad de reducción rápida de postcarga'
      ],
      organSystem: 'pulmon'
    },
    {
      id: 2,
      year: '2024 A',
      case: 'Varón de 49 años, ingresa a emergencia por cuadro súbito de cefalea y trastorno de sensorio. Examen: PA 180/95 mmHg, FC 98 X; tomografía cerebral muestra hiperdensidad en región de tálamo.',
      question: 'Se inicia tratamiento antihipertensivo con el objetivo de presión arterial sistólica (PAS) menor de mmHg en la primera hora.',
      options: [
        { id: 'A', text: '180' },
        { id: 'B', text: '150' },
        { id: 'C', text: '140', correct: true },
        { id: 'D', text: '160' }
      ],
      explanation: 'En hemorragia intracerebral, el objetivo es mantener PAS < 140 mmHg en la primera hora para prevenir expansión del hematoma.',
      keyPoints: [
        'Hemorragia talámica',
        'Control gradual de PA',
        'Prevención de expansión del hematoma',
        'Meta específica de PAS'
      ],
      organSystem: 'cerebro'
    },
    {
      id: 3,
      year: '2024 B',
      case: 'Mujer de 36 años, hace una hora es encontrada en su domicilio con trastorno de sensorio. Examen: PA 200/125 mmHg, FC 88 X, FR 20 X; neurológico: disártrica con hemiparesia derecha. TC: hipodensidad en región talámica izquierda.',
      question: '¿Cuál es el antihipertensivo de elección?',
      options: [
        { id: 'A', text: 'Nitroglicerina' },
        { id: 'B', text: 'Labetalol', correct: true },
        { id: 'C', text: 'Esmolol' },
        { id: 'D', text: 'Nitroprusiato de sodio' }
      ],
      explanation: 'El Labetalol es el fármaco de elección en eventos cerebrovasculares por su control gradual de la presión arterial.',
      keyPoints: [
        'Evento cerebrovascular agudo',
        'Control gradual de PA',
        'Evitar hipotensión brusca',
        'Preservación de perfusión cerebral'
      ],
      organSystem: 'cerebro'
    },
    {
      id: 4,
      year: '2023 A',
      case: 'Mujer de 46 años ingresa por dolor precordial opresivo de una hora de evolución. Examen: PA: 200/130 mmHg, FC: 100 X\', FR: 22 X\', T°: 37°C. EKG: supradesnivel del segmento ST en precordiales.',
      question: '¿Cuál es el medicamento de elección?',
      options: [
        { id: 'A', text: 'Nicardipino' },
        { id: 'B', text: 'Hidralacina' },
        { id: 'C', text: 'Nitroglicerina', correct: true },
        { id: 'D', text: 'Nitroprusiato de sodio' }
      ],
      explanation: 'La Nitroglicerina es de elección en síndrome coronario agudo por su efecto vasodilatador coronario.',
      keyPoints: [
        'Síndrome coronario agudo',
        'Supradesnivel ST',
        'Vasodilatación coronaria',
        'Reducción de precarga'
      ],
      organSystem: 'corazon'
    },
    {
      id: 5,
      year: '2021 B',
      case: 'Mujer de 38 años, obesa e hipertensa mal controlada, ingresa por dificultad respiratoria, tos y hemoptisis. Examen: PA: 220/120 mmHg; FC: 100 X; FR: 26 X; SatO2: 93%. Rx de tórax: infiltrados bilaterales en alas de mariposa.',
      question: '¿Cuál es el antihipertensivo de elección?',
      options: [
        { id: 'A', text: 'Enalaprilato' },
        { id: 'B', text: 'Hidralazina' },
        { id: 'C', text: 'Labetalol' },
        { id: 'D', text: 'Nitroprusiato de sodio', correct: true },
        { id: 'E', text: 'Nicardipina' }
      ],
      explanation: 'El Nitroprusiato es ideal en edema pulmonar por su potente efecto vasodilatador y rápido inicio de acción.',
      keyPoints: [
        'Edema pulmonar agudo',
        'Patrón en alas de mariposa',
        'Hemoptisis',
        'Necesidad de acción rápida'
      ],
      organSystem: 'pulmon'
    }
  ];
  
  export default emergenciasHipertensivas;