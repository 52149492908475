const insuficienciacardiaCases = [
  {
    id: 1,
    year: '2023 A',
    case: 'Varón de 37 años, acude por presentar disnea y hemoptisis. Antecedente: hace 5 meses arritmia cardíaca. Rx de tórax: congestión pulmonar. Test de esfuerzo: genera arritmias y signos de isquemia miocárdica.',
    question: '¿Cuál es el mecanismo fisiológico alterado?',
    options: [
      { id: 'A', text: 'Disminución del llenado ventricular izquierdo', correct: true },
      { id: 'B', text: 'Disminución de la gradiente de presión atrioventricular' },
      { id: 'C', text: 'Aumento de resistencia pulmonar' },
      { id: 'D', text: 'Disminución del llenado ventricular derecho' }
    ],
    explanation:
      'La disminución del llenado ventricular izquierdo conduce a congestión pulmonar y síntomas como disnea y hemoptisis. La arritmia y la isquemia miocárdica afectan la función del ventrículo izquierdo.',
    keyPoints: [
      'Disnea y hemoptisis indican congestión pulmonar',
      'Antecedente de arritmia e isquemia miocárdica',
      'Disminución del llenado ventricular izquierdo es el mecanismo afectado',
      'Signos de insuficiencia cardíaca izquierda'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 2,
    year: '2014 A',
    case: 'Paciente con signos clínicos de ingurgitación yugular, hepatomegalia y ascitis.',
    question: '¿Cuál de los siguientes hallazgos clínicos son más específicos de insuficiencia cardiaca derecha?',
    options: [
      { id: 'A', text: 'Ingurgitación yugular, hepatomegalia y ascitis', correct: true },
      { id: 'B', text: 'Disnea paroxística nocturna, palpitaciones y tos' },
      { id: 'C', text: 'Síncope, angina y arritmia' },
      { id: 'D', text: 'Edema, hemoptisis y ortopnea' },
      { id: 'E', text: 'Nicturia, ictericia y hepatomegalia' }
    ],
    explanation:
      'Los signos de ingurgitación yugular, hepatomegalia y ascitis son específicos de insuficiencia cardíaca derecha debido a la congestión venosa sistémica.',
    keyPoints: [
      'Insuficiencia cardíaca derecha afecta circulación sistémica',
      'Ingurgitación yugular es un signo de congestión venosa',
      'Hepatomegalia y ascitis resultan de congestión hepática',
      'Síntomas específicos de falla derecha'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 3,
    year: '2010 A',
    case: 'Paciente mayor de 40 años con sospecha de insuficiencia cardíaca.',
    question: 'El signo específico de insuficiencia cardíaca en mayores de 40 años es:',
    options: [
      { id: 'A', text: 'Edemas' },
      { id: 'B', text: 'Soplo sistólico aórtico' },
      { id: 'C', text: 'Tercer ruido', correct: true },
      { id: 'D', text: 'Disnea paroxística nocturna' },
      { id: 'E', text: 'Soplo diastólico mitral' }
    ],
    explanation:
      'La presencia de un tercer ruido (S3) en mayores de 40 años es un signo específico de insuficiencia cardíaca debido al llenado ventricular rápido en un ventrículo dilatado.',
    keyPoints: [
      'Tercer ruido es audible en insuficiencia cardíaca',
      'Más específico en pacientes mayores de 40 años',
      'Indica disfunción sistólica ventricular',
      'Resultado del llenado rápido en ventrículo dilatado'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 4,
    year: '2024 A',
    case: 'Neonato traído a emergencia por lactancia entrecortada con succión débil progresiva y respiración rápida. Examen: FC 158 X\', FR 70 X\', SatO2 90%; peso: 3,200 g; se palpa hígado a 3 cm del reborde costal derecho, se ausculta soplo sistólico en mesocardio.',
    question: '¿En qué diagnóstico sospecha?',
    options: [
      { id: 'A', text: 'Taquipnea transitoria' },
      { id: 'B', text: 'Sepsis neonatal' },
      { id: 'C', text: 'Neumonía comunitaria' },
      { id: 'D', text: 'Insuficiencia cardíaca', correct: true }
    ],
    explanation:
      'Los signos de taquipnea, hepatomegalia y soplo cardíaco sugieren insuficiencia cardíaca en el neonato, posiblemente por una cardiopatía congénita.',
    keyPoints: [
      'Respiración rápida y succión débil en el neonato',
      'Hepatomegalia indica congestión venosa',
      'Soplo sistólico sugiere anomalía cardíaca',
      'Insuficiencia cardíaca neonatal probable'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 5,
    year: '2023 B',
    case: 'Varón de 47 años, con antecedente de infarto de miocardio hace un año, llega a emergencia por presentar disnea, nicturia y fatigabilidad.',
    question: 'De acuerdo a su diagnóstico, ¿qué mecanismo fisiológico es el que presenta?',
    options: [
      { id: 'A', text: 'Disminución del gasto cardíaco', correct: true },
      { id: 'B', text: 'Falla de marcapasos intrínseco' },
      { id: 'C', text: 'Disminución de la presión venosa' },
      { id: 'D', text: 'Aumento del gasto cardíaco' }
    ],
    explanation:
      'La insuficiencia cardíaca post-infarto conduce a disminución del gasto cardíaco debido a la pérdida de masa miocárdica funcional.',
    keyPoints: [
      'Antecedente de infarto de miocardio',
      'Síntomas de bajo gasto cardíaco: fatigabilidad, nicturia',
      'Disminución del gasto cardíaco es el mecanismo principal',
      'Insuficiencia cardíaca sistólica probable'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 6,
    year: '2012 A',
    case: 'Consulta sobre las patologías que causan con mayor frecuencia insuficiencia cardíaca.',
    question: '¿Cuáles de las siguientes patologías causan con mayor frecuencia insuficiencia cardíaca?',
    options: [
      { id: 'A', text: 'Hipertensión arterial y estenosis mitral' },
      { id: 'B', text: 'Enfermedad coronaria crónica y estenosis mitral' },
      { id: 'C', text: 'Hipertensión arterial y miocardiopatías' },
      { id: 'D', text: 'Enfermedad coronaria crónica y valvulopatías aórticas' },
      { id: 'E', text: 'Hipertensión arterial y enfermedad coronaria crónica', correct: true }
    ],
    explanation:
      'La hipertensión arterial y la enfermedad coronaria crónica son las causas más frecuentes de insuficiencia cardíaca debido al estrés continuo sobre el miocardio.',
    keyPoints: [
      'Hipertensión arterial aumenta la carga cardíaca',
      'Enfermedad coronaria reduce el flujo sanguíneo al miocardio',
      'Ambas condiciones llevan a disfunción ventricular',
      'Principales causas de insuficiencia cardíaca en adultos'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 7,
    year: '2023 B',
    case: 'Lactante de dos meses es traído por presentar llanto constante, lactancia entrecortada, sudoración profusa retrocervical durante la lactancia. Examen: FC: 130 X\', FR: 28 X\', Tº: 36.5 ºC, peso: 3200 g, talla: 50 cm; pálido, adelgazado; tórax: roncantes y subcrepitantes bilaterales; soplo sistólico II/VI en mesocardio, hígado a 3 cm del reborde costal derecho.',
    question: '¿Cuál es el diagnóstico?',
    options: [
      { id: 'A', text: 'Neumonía aspirativa' },
      { id: 'B', text: 'Insuficiencia cardíaca', correct: true },
      { id: 'C', text: 'Taquicardia sinusal' },
      { id: 'D', text: 'Fibrosis quística' }
    ],
    explanation:
      'Los signos de sudoración durante la lactancia, hepatomegalia y soplo cardíaco indican insuficiencia cardíaca en un lactante, posiblemente por defecto cardíaco congénito.',
    keyPoints: [
      'Sudoración y fatiga durante la alimentación',
      'Presencia de soplo sistólico sugiere cardiopatía',
      'Hepatomegalia por congestión venosa',
      'Indicativo de insuficiencia cardíaca en lactantes'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 8,
    year: '2014 EX A',
    case: 'Paciente de 55 años con anasarca, hiponatremia hipovolémica con concentración urinaria de sodio menor de 20 mmol/L.',
    question: '¿Cuál es el diagnóstico más probable?',
    options: [
      { id: 'A', text: 'Insuficiencia cardíaca', correct: true },
      { id: 'B', text: 'Nefropatía perdedora de sal' },
      { id: 'C', text: 'Hipotiroidismo' },
      { id: 'D', text: 'Secreción inapropiada de HAD' },
      { id: 'E', text: 'Falla renal aguda' }
    ],
    explanation:
      'La insuficiencia cardíaca puede causar hiponatremia hipovolémica con baja excreción urinaria de sodio debido a la activación del sistema renina-angiotensina-aldosterona.',
    keyPoints: [
      'Anasarca indica edema generalizado',
      'Hiponatremia con sodio urinario bajo',
      'Activación neurohumoral en insuficiencia cardíaca',
      'Descarta otras causas con sodio urinario alto'
    ],
    organSystem: 'cardiovascular'
  },
  {
    id: 9,
    year: '2023 A',
    case: 'Mujer de 73 años, quien acude por presentar fatigabilidad. Examen: ingurgitación yugular, hepatomegalia, ascitis, derrame pleural, edema periférico en ambos maléolos.',
    question: '¿Cuál es su diagnóstico?',
    options: [
      { id: 'A', text: 'Estenosis aórtica' },
      { id: 'B', text: 'Insuficiencia cardíaca izquierda' },
      { id: 'C', text: 'Falla multivalvular' },
      { id: 'D', text: 'Insuficiencia cardíaca derecha', correct: true }
    ],
    explanation:
      'Los signos de ingurgitación yugular, hepatomegalia y edema periférico son característicos de insuficiencia cardíaca derecha debido a congestión venosa sistémica.',
    keyPoints: [
      'Fatigabilidad y signos de congestión sistémica',
      'Edema periférico bilateral',
      'Hepatomegalia y ascitis por congestión venosa',
      'Indicativo de insuficiencia cardíaca derecha'
    ],
    organSystem: 'cardiovascular'
  }
];

export default insuficienciacardiaCases;